import React, { useEffect, useState } from "react";
import Fehler from "../layout/Fehler";
import Textfeld from "../Textfeld";
import AbstimmungInfos from "./AbstimmungInfos";
import AbstimmungListe from "./AbstimmungListe";
import api from "../../functions/api";

const Abstimmung = ({
	abstimmung,
	role = null,
	data = { nominations: [], type: "standard" },
}) => {
	const vorschlaege = data.nominations;

	const [abstimmungID, setAbstimmungID] = useState(abstimmung);
	const [eintraege, setEintraege] = useState(vorschlaege);
	const [infos, setInfos] = useState([]);
	const [gueltig, setGueltig] = useState(true);
	const [laden, setLaden] = useState(true);

	const [beendet, setBeendet] = useState(data.end && data.end < Date.now());
	const [abstimmungType, setAbstimmungType] = useState(data.type);

	async function getPoll() {
		let pollPromise;
		if (role === null) {
			pollPromise = api.getPoll(abstimmung);
		} else {
			pollPromise = api.getPollByRole(role);
		}
		pollPromise
			.then((res) => {
				if (role !== null) {
					setAbstimmungID(res.pollID);
				}

				setInfos(res);
				if (res.end !== null) {
					const ende = new Date(res.end.replace(" ", "T"));
					const jetzt = new Date();

					setBeendet(ende < jetzt);
				} else {
					setBeendet(false);
				}

				if (res.type) {
					setAbstimmungType(res.type);
				}

				setEintraege(res.nominations);
				setLaden(false);
			})
			.catch(() => {
				setGueltig(false);
			});
	}

	useEffect(() => {
		getPoll();

		const interval = setInterval(() => getPoll(), 10000);
		return () => clearInterval(interval);
	}, []);

	function postNomination(nomination) {
		api.postNomination(abstimmungID, nomination)
			.then(() => {
				setTimeout(() => getPoll(), 100);
			})
			.catch(() => {
				setGueltig(false);
			});
	}

	if (gueltig) {
		return (
			<div
				className={
					laden
						? "poll poll-loading poll-loading--loaded"
						: "poll poll-loading"
				}
			>
				<AbstimmungInfos infos={infos} reload={() => getPoll()} />

				<AbstimmungListe
					eintraege={eintraege}
					abstimmung={abstimmungID}
					getAbstimmung={() => getPoll()}
					beendet={beendet}
					type={abstimmungType}
				/>

				{!beendet && (
					<Textfeld
						onSubmit={(text) => postNomination(text)}
						placeholder="Neuer Vorschlag"
					/>
				)}
			</div>
		);
	} else {
		return <Fehler />;
	}
};

export default Abstimmung;
